<template>
    <div>
        <v-row>
            <v-col>
                <v-card flat color="#f1f1f1" style="max-width: 600px">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="title">
                                {{ __('Restrição de envio', 'settings') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-card-text>
                        <p>
                            {{ __('A restrição da companhia serve para impedir que os utilizadores enviem anexos sigilosos para a companhia por engano, todo anexo bloqueado não poderá ser enviado para a companhia', 'settings') }}
                        </p>
                        
                        <p>
                            {{ __('Defina quais categorias deverão bloquear os anexos por defeito, obrigando os utilizadores a desbloquearem manualmente os anexos para enviá-los', 'settings') }}
                            
                        </p>
                    </v-card-text>
                </v-card>

                <v-card flat class="ma-4" style="max-width: 600px">
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon v-if="uncategorized" color="primary">
                                    mdi-lock
                                </v-icon>
                                <v-icon v-else color="grey">
                                    mdi-lock-open
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ __('Bloquear anexos sem categorias', 'settings') }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ __('Por defeito bloqueia todos os uploads não categorizados', 'settings') }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-switch @change="handleSwitchUncategorized($event)" :value="uncategorized" :disabled="!canEdit"></v-switch>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>

                <v-card flat class="ma-4" style="max-width: 600px">
                    <v-card-title>
                        {{ __('Categorias', 'settings') }}
                    </v-card-title>
                    <v-card-text>
                        {{ __('Ao ativar a restrição de envio de categoria, todos os anexos presentes na mesma serão afetados', 'settings') }}
                    </v-card-text>
                    <v-list>
                        <div v-for="category in categories" :key="category.id">
                            <v-list-item>
                                <v-list-item-icon>
                                    <v-icon v-if="category.is_company_locked" color="primary">
                                        mdi-lock
                                    </v-icon>
                                    <v-icon v-else color="grey">
                                        mdi-lock-open
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ category.description }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-switch v-model="category.is_company_locked" @change="handleSwitchLockCategory(category, $event)" :disabled="!canEdit"></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </div>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import i18n from '@/mixins/i18n';

export default {
    mixins: [i18n],
    data() {
        return {
            categories: [],
            uncategorized: false
        }
    },

    methods: {
        async getData() {
            const response = await this.$http.get('/config/attachments/insurer/restriction');
            this.categories = response.data.categories
            this.uncategorized = response.data.uncategorized
        },
        async handleSwitchLockCategory(category, event) {
            const response = await this.$http.post('/config/attachments/insurer/restriction/category_company_lock', {
                category_id: category.id,
                is_company_locked: event
            });

            this.categories.map(category => {
                if(category.id === response.data.id) {
                    return response.data;
                }

                return category;
            })
            const state = event === true ? this.__('bloqueada', 'settings') : this.__('desbloqueada', 'settings')
            this.$store.commit('alert', this.$capitalize(this.$tc('model.productbudget_category')) + ` ${category.description} ${state}`)
        },
        async handleSwitchUncategorized(event) {
            const locked = !!event
            await this.$http.post('config/attachments/insurer/restriction/uncategorized_company_lock', {
                is_company_locked: locked
            });

            this.uncategorized = locked

            const state = locked === true ? this.__('bloqueada', 'settings') : this.__('desbloqueada', 'settings')
            this.$store.commit('alert', this.__('Anexos de categoria', 'settings') + ` ${state}`)
        }
    },

    computed: {
        canEdit: function() {
            return this.$store.getters['auth/getScope']('configs.attachments.edit')
        }
    },

    created() {
        this.getData();
    }
}
</script>